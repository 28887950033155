<template>
  <ListLesson />
</template>

<script>
import ListLesson from '@/components/basic/lesson/ListLesson.vue'

export default {
  components: {
    ListLesson,
  },
}
</script>
