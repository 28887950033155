<template>
  <div class="space-large">
    <b-card>
      商品1
    </b-card>
    <b-card>
      商品2
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    onMounted(() => {
      window.console.log('🍎')
    })
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.3rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
</style>
